import React from "react";
import moment from "moment";
import HttpCliente from "../../../services/HttpCliente";
import axios from "axios";
import { Panel, PanelHeader, PanelBody } from "../../../components/panel/panel.jsx";
import AddEditBankAccountPopup from "../../../components/popups/add-edit-bank-account";
import AddEditPhoneNumberPopup from "../../../components/popups/add-edit-phone-number";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";

const NewCustomer = () => {
  let { kyc_id, edit_mode } = useParams();
  let path = window.location.pathname;
  if (kyc_id !== undefined) {
    path = path.slice(0, path.lastIndexOf("/"));
    path = path.slice(0, path.lastIndexOf("/"));
  }
  const [customer_id, set_customer_id] = React.useState(
    kyc_id !== undefined ? kyc_id : 0
  );

  const [customerBankData, setCustomerBankData] = React.useState([]);
  const [customerPhoneData, setCustomerPhoneData] = React.useState([]);
  const [provincesData, setProvincesData] = React.useState([]);
  const [kycStatusesData, setKycStatusesData] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);

  const [customer_name, set_customer_name] = React.useState("");
  const [customer_registry, set_customer_registry] = React.useState("");
  const [customer_email, set_customer_email] = React.useState("");
  const [customer_id_type, set_customer_id_type] = React.useState("");
  const [customer_id_number, set_customer_id_number] = React.useState("");
  const [customer_province, set_customer_province] = React.useState("");
  const [birth, set_birth] = React.useState<any>(undefined);
  const [kyc_status_customer_id, set_kyc_status_customer_id] =
    React.useState("");
  const [kyc_status_customer_name, set_kyc_status_customer_name] =
    React.useState("");
  const [kyc_status_notes, set_kyc_status_notes] = React.useState("");
  const [created_by_user, set_created_by_user] = React.useState("");
  const [created_at, set_created_at] = React.useState<any>("");
  const [updated_by_user, set_updated_by_user] = React.useState("");
  const [updated_at, set_updated_at] = React.useState<any>("");
  const [bankEditMode, setBankEditMode] = React.useState(false);
  const [bankViewMode, setBankViewMode] = React.useState(false);
  const [bankEditModeBank, setBankEditModeBank] = React.useState(false);
  const [bankToHandle, setBankToHandle] = React.useState("");
  const [bankEditPopup, setBankEditPopup] = React.useState(false);
  const [phoneEditMode, setPhoneEditMode] = React.useState(false);
  const [phoneViewMode, setPhoneViewMode] = React.useState(false);
  const [phoneEditModePhone, setPhoneEditModePhone] = React.useState(false);
  const [phoneToHandle, setPhoneToHandle] = React.useState("");
  const [phoneEditPopup, setPhoneEditPopup] = React.useState(false);
  const [userData, setUserData] = React.useState<any>("");
  const [alertType, setAlertType] = React.useState("");
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [borderOutlineColor, setborderOutlineColor] = React.useState("#d5dbe0");
  const [formComplete, setFormComplete] = React.useState(false);
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info");
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] =
    React.useState(false);
  const [sweetAlertActiveVariant2, setSweetAlertActiveVariant2] =
    React.useState(false);
  const [sweetAlertMessage1Variant2, setSweetAlertMessage1Variant2] =
    React.useState("Temp");
  const [sweetAlertMessage2Variant2, setSweetAlertMessage2Variant2] =
    React.useState("Default");
  const [sweetAlertActiveVariant3, setSweetAlertActiveVariant3] =
    React.useState(false);
  const sweetAlertMessage1Variant3 = "Temp";
  const editBankAccountButtonHandler = () => {
    setBankEditPopup(true);
  };
  const editPhoneNumberButtonHandler = () => {
    setPhoneEditPopup(true);
  };
  const deleteBankAccountButtonHandler = () => {
    setAlertType("deleteBank");
    toggleSweetAlertVariant2(
      "Are you sure?",
      "Do you want to delete this Bank Account?"
    );
  };
  const deletePhoneNumberButtonHandler = () => {
    setAlertType("deletePhone");
    toggleSweetAlertVariant2(
      "Are you sure?",
      "Do you want to delete this Phone Number?"
    );
  };
  const deleteBankAccountActionProceed = () => {
    axios
      .delete(`/customer/${customer_id}/bank/${bankToHandle}`)
      .then((response) => {
        // console.log(response);
        HttpCliente.get(`/customer/${customer_id}/bank`)
          .then((response) => {
            setCustomerBankData(response.data);
          })
          .catch((error) => {
            setCustomerBankData([]);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deletePhoneNumberActionProceed = () => {
    axios
      .delete(`/customer/${customer_id}/phone-numbers/${phoneToHandle}`)
      .then((response) => {
        // console.log(response);
        HttpCliente.get(`/customer/${customer_id}/phone-numbers`)
          .then((response) => {
            setCustomerPhoneData(response.data);
          })
          .catch((error) => {
            setCustomerPhoneData([]);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  ////////////////////////////////////////////////RE RENDER FOR BANK DATA////////////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    if (customer_id !== 0 && bankEditPopup === false) {
      HttpCliente.get(`/customer/${customer_id}/bank`)
        .then((response) => {
          setCustomerBankData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [bankEditPopup, sweetAlertActiveVariant2, sweetAlertActiveVariant3]);

  React.useEffect(() => {
    if (customer_id !== 0 && phoneEditPopup === false) {
      HttpCliente.get(`/customer/${customer_id}/phone-numbers`)
        .then((response) => {
          setCustomerPhoneData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [phoneEditPopup, sweetAlertActiveVariant2, sweetAlertActiveVariant3]);

  React.useEffect(() => {
    HttpCliente.get("/Auth/Ping")
      .then((response) => {
        setUserData(response.data);
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  }, []);
  ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////
  const toggleSweetAlert = (message: string, success: any, type: ModalGeneralType) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

  const toggleSweetAlertVariant2 = (message: string, message2: string) => {
    setSweetAlertActiveVariant2(!sweetAlertActiveVariant2);
    setSweetAlertMessage1Variant2(message);
    setSweetAlertMessage2Variant2(message2);
  };

  const addBankHandler = () => {
    //HANDLE BANK ADDITION
    setBankEditPopup(true);
  };
  const addPhoneHandler = () => {
    //HANDLE PHONE ADDITION
    setPhoneEditPopup(true);
  };
  const saveButtonHandler = () => {
    if (
      customer_name === "" ||
      customer_registry === "" || customer_registry === null ||
      customer_email === "" || customer_email === null ||
      customer_id_type === "" ||
      customer_id_number === "" ||
      kyc_status_customer_id === "" ||
      kyc_status_customer_name === ""
    ) {
      toggleSweetAlert("Please complete all mandatory fields", false, "danger");
      setborderOutlineColor("red");
    } else if (customerBankData.length === 0 && customerPhoneData.length === 0) {
      toggleSweetAlert(
        "Please add atleast one bank account or one phone number for the customer",
        false,
        "info"
      );
    } else {
      saveButtonPatchRequest();
    }
  };
  const saveButtonPostRequest = (showSweetAlert: any) => {
    let sigData;

    sigData = {
      customer_name: customer_name,
      customer_registry: customer_registry,
      customer_email: customer_email,
      customer_id_type: customer_id_type,
      customer_id_number: customer_id_number,
      kyc_status_customer_id: parseInt(kyc_status_customer_id, 10),
      birth: moment(birth).unix(),
      kyc_status_customer_name: parseInt(kyc_status_customer_name, 10),
      customer_province: customer_province,
      kyc_status_notes: kyc_status_notes,
      created_by_user: userData.full_name,
      created_at: moment(new Date()).unix(),
    };

    axios
      .post(`/customer`, sigData)
      .then((response) => {
        // console.log(response);
        set_customer_id(response.data.customer_id);
        if (showSweetAlert === true) {
          toggleSweetAlert("Success!", true, "success");
          if (typeof kyc_id !== `undefined`) {
            if (typeof window !== `undefined`) {
              window.location.href = path;
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (showSweetAlert === true)
          toggleSweetAlert(`Error: ${error}`, false, "danger");
        if (showSweetAlert === false) {
          toggleSweetAlert(
            `Could not create user, please try again`,
            false,
            "danger"
          );
          setBankEditPopup(false);
          setPhoneEditPopup(false);
        }
      });
  };
  const saveButtonPatchRequest = () => {
    let sigData;
    sigData = {
      customer_id: customer_id,
      customer_name: customer_name,
      customer_registry: customer_registry,
      customer_email: customer_email,
      customer_id_type: customer_id_type,
      customer_id_number: customer_id_number,
      kyc_status_customer_id: parseInt(kyc_status_customer_id, 10),
      birth: moment(birth).unix(),
      kyc_status_customer_name: parseInt(kyc_status_customer_name, 10),
      customer_province: customer_province,
      kyc_status_notes: kyc_status_notes,
      updated_by_user: userData.full_name,
      updated_at: moment(new Date()).unix(),
    };

    axios
      .patch(`/customer/${customer_id}`, sigData)
      .then((response) => {
        // console.log(response);
        setFormComplete(true);
        toggleSweetAlert("Success!", true, "success");
      })
      .catch((error) => {
        toggleSweetAlert(`Error: ${error}`, false, "danger");
      });
  };
  React.useEffect(() => {
    HttpCliente.get(`/customer`)
      .then((response) => {
        setCustomerData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    if (customer_id !== 0) {
      HttpCliente.get(`/customer/${customer_id}`)
        .then((response: any) => {
          set_customer_name(response.data.customer_name);
          set_customer_registry(response.data.customer_registry);
          set_customer_email(response.data.customer_email);
          set_customer_id_type(response.data.customer_id_type);
          set_customer_id_number(response.data.customer_id_number);
          set_customer_province(response.data.customer_province);
          set_kyc_status_customer_id(response.data.kyc_status_customer_id);
          set_kyc_status_customer_name(response.data.kyc_status_customer_name);
          set_kyc_status_notes(response.data.kyc_status_notes);
          set_birth(
            new Date(
              moment.unix(response.data.birth).toDate().getUTCFullYear(),
              moment.unix(response.data.birth).toDate().getUTCMonth(),
              moment.unix(response.data.birth).toDate().getUTCDate(),
              moment.unix(response.data.birth).toDate().getUTCHours(),
              moment.unix(response.data.birth).toDate().getUTCMinutes(),
              moment.unix(response.data.birth).toDate().getUTCSeconds()
            )
          );
          set_created_by_user(response.data.created_by_user);
          set_created_at(response.data.created_at);
          set_updated_by_user(response.data.updated_by_user);
          set_updated_at(response.data.updated_at);
        })
        .catch((error) => {
          console.log("error:", error);
        });
      HttpCliente.get(`/customer/${customer_id}/bank`)
        .then((response) => {
          setCustomerBankData(response.data);
        })
        .catch((error) => {
          setCustomerBankData([]);
        });
      HttpCliente.get(`/customer/${customer_id}/phone-numbers`)
        .then((response) => {
          setCustomerPhoneData(response.data);
        })
        .catch((error) => {
          setCustomerPhoneData([]);
        });
    }
    HttpCliente.get(`/province`)
      .then((response) => {
        setProvincesData(response.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    HttpCliente.get(`/status/kyc`)
      .then((response) => {
        setKycStatusesData(response.data);
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [1]);

  let titlePage = "New Customer";
  if (kyc_id !== undefined) {
    titlePage = "Edit Customer";
    if (edit_mode === "view") titlePage = "View Customer";
  }

  function handleCustomerIdNumberInput(value: any) {
    if (customer_id_type === "DNI") {
      if (value.length <= 8) set_customer_id_number(value);
    } else if (customer_id_type === "CE") {
      if (value.length <= 9) set_customer_id_number(value);
    } else if (customer_id_type === "PAS") {
      if (value.length <= 20) set_customer_id_number(value);
    }
  }

  return (
    <div id="animate">
      {bankEditPopup ? (
        <ModalGeneral
          isModalOpen={bankEditPopup}
          onlyModal={true}
        >
            <AddEditBankAccountPopup
              bankViewMode={bankViewMode}
              bankEditMode={bankEditMode}
              bankEditModeBank={bankEditModeBank}
              setBankEditPopup={setBankEditPopup}
              toggleSweetAlert={toggleSweetAlert}
              customerId={customer_id}
              customerData={customerData}
            />
        </ModalGeneral>
      ) : null}
      {phoneEditPopup ? (
        <ModalGeneral
          isModalOpen={phoneEditPopup}
          onlyModal={true}
        >
            <AddEditPhoneNumberPopup
              phoneViewMode={phoneViewMode}
              phoneEditMode={phoneEditMode}
              phoneEditModePhone={phoneEditModePhone}
              setPhoneEditPopup={setPhoneEditPopup}
              toggleSweetAlert={toggleSweetAlert}
              customerId={customer_id}
              customerData={customerData}
            />
        </ModalGeneral>
      ) : null}
      {sweetAlertActive ? (
        <ModalGeneral
          isModalOpen={sweetAlertActive}
          onlyModal={false}
          title={""}
          type={sweetAlertSuccess}
          onConfirm={() => {
            if (formComplete === true) {
              if (typeof kyc_id !== `undefined`) {
                if (typeof window !== `undefined`) {
                  window.location.href = path;
                }
              } else if (typeof window !== `undefined`) {
                window.location.href = `${process.env.REACT_APP_Sub_Domain}/customers/list/customer-list`;
              }
            }
            setSweetAlertActive(false);
          }}
          onClose={() => {
            setSweetAlertActive(false);
          }}
        >
          <div>
            <h3>{sweetAlertMessage}</h3>
          </div>
        </ModalGeneral>
      ) : null}
      {sweetAlertActiveVariant2 ? (
        <ModalGeneral
          isModalOpen={sweetAlertActiveVariant2}
          onlyModal={false}
          type="info"
          title={sweetAlertMessage1Variant2}
          onConfirm={() => {
            if (alertType === "deleteBank") {
              deleteBankAccountActionProceed();
            }
            if (alertType === "deletePhone") {
              deletePhoneNumberActionProceed();
            }
            setSweetAlertActiveVariant2(false);
          }}
          onCancel={() => {
            if (alertType !== "nextRequest") {
            } else {
            }
            setAlertType("");
            setSweetAlertActiveVariant2(false);
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Yes"
        >
          <h5>{sweetAlertMessage2Variant2}</h5>
        </ModalGeneral>
      ) : null}
      {sweetAlertActiveVariant3 ? (
        <ModalGeneral
          isModalOpen={sweetAlertActiveVariant3}
          onlyModal={false}
          type={"info"}
          title={sweetAlertMessage1Variant3}
          onConfirm={() => {
            if (alertType === "deleteCustomer") {
              deleteBankAccountActionProceed();
              deletePhoneNumberActionProceed();
            }
            setSweetAlertActiveVariant3(false);
          }}
          onCancel={() => {
            setAlertType("");
            setSweetAlertActiveVariant3(false);
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Yes"
        />
      ) : null}
      <h1 className="page-header">{titlePage}</h1>
      <Panel>
        <PanelHeader noButton>Main Info</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputCustomer">
                <strong>Customer</strong>
              </label>
              <input
                style={{
                  border: `solid 1px ${
                    customer_name === "" ? borderOutlineColor : "#d5dbe0"
                  }`,
                }}
                type="text"
                className="form-control"
                id="InputCustomer"
                placeholder="Name"
                disabled={edit_mode === "view" ? true : false}
                value={customer_name}
                onChange={(e) => {
                  set_customer_name(e.target.value);
                }}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputCustomerRegistry">
                <strong>Registry Name</strong>
              </label>
              <input
                style={{
                  border: `solid 1px ${
                    (customer_registry === "" || customer_registry === null) ? borderOutlineColor : "#d5dbe0"
                  }`,
                }}
                type="text"
                className="form-control"
                id="InputCustomerRegistry"
                placeholder={edit_mode === "view" ? "" : "Registry Name"}
                disabled={edit_mode === "view" ? true : false}
                value={customer_registry}
                onChange={(e) => {
                  set_customer_registry(e.target.value);
                }}
              />
            </div>
            <div className="col-md-3 mb-3">
              <div className="row">
                <div className="col-md-5">
                  <label className="form-label" htmlFor="InputIdNumber">
                    <strong>ID Number</strong>
                  </label>
                  <br />
                  <select
                    style={{
                      border: `solid 1px ${
                        customer_id_type === "" ? borderOutlineColor : "#d5dbe0"
                      }`,
                    }}
                    className="form-select"
                    name="account-name-status"
                    id="InputIdType"
                    disabled={edit_mode === "view" ? true : false}
                    value={customer_id_type}
                    onChange={(e) => {
                      set_customer_id_type(e.target.value);
                    }}
                  >
                    <option value="" disabled hidden>
                      Select
                    </option>
                    <option>DNI</option>
                    <option>CE</option>
                    <option>PAS</option>
                  </select>
                </div>
                <div className="col-md-7">
                  <label className="form-label">&nbsp;</label>
                  <br />
                  <input
                    style={{
                      display: "inline",
                      border: `solid 1px ${
                        customer_id_number === "" ? borderOutlineColor : "#d5dbe0"
                      }`,
                    }}
                    type="text"
                    className="form-control col-md-8"
                    id="InputIdNumber"
                    placeholder="Enter ID"
                    disabled={edit_mode === "view" ? true : false}
                    value={customer_id_number}
                    onChange={(e) => {
                      handleCustomerIdNumberInput(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputDateOfBirth">
                <strong>Date of Birth</strong>
              </label>
                <form autoComplete="off">
                    <DatePicker
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      id="InputDateTo"
                      placeholderText={"Please select a date"}
                      selected={birth}
                      disabled={edit_mode === "view" ? true : false}
                      onChange={(e: any) => {
                        set_birth(e);
                      }}
                    />
                </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputEmail">
                <strong>Email</strong>
              </label>
              <input
                type="text"
                style={{
                  border: `solid 1px ${
                    (customer_email === "" || customer_email === null) ? borderOutlineColor : "#d5dbe0"
                  }`,
                }}
                className="form-control"
                id="InputEmail"
                placeholder="Please enter an email"
                disabled={edit_mode === "view" ? true : false}
                value={customer_email}
                onChange={(e) => {
                  set_customer_email(e.target.value);
                }}
              />
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputProvince">
                <strong>Province</strong>
              </label>
              <br />
              <select
                className="form-select"
                name="province"
                id="InputProvince"
                disabled={edit_mode === "view" ? true : false}
                value={customer_province}
                onChange={(e) => {
                  set_customer_province(e.target.value);
                }}
              >
                <option value="" disabled hidden>
                  Select Province
                </option>
                {typeof provincesData !== `undefined`
                  ? provincesData.map((value: any, index: any) => {
                      return (
                        <option value={value.provinceID} key={value.provinceID}>
                          {value.provinceID}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputIdStatus">
                <strong>ID: Status</strong>
              </label>
              <br />
              <select
                style={{
                  border: `solid 1px ${
                    kyc_status_customer_id === ""
                      ? borderOutlineColor
                      : "#d5dbe0"
                  }`,
                }}
                className="form-select"
                name="id_status"
                id="InputIdStatus"
                disabled={edit_mode === "view" ? true : false}
                value={kyc_status_customer_id}
                onChange={(e) => {
                  set_kyc_status_customer_id(e.target.value);
                }}
              >
                <option value="" disabled hidden>
                  Select Status
                </option>
                {typeof kycStatusesData !== `undefined`
                  ? kycStatusesData.map((value: any, index: any) => {
                      return (
                        <option
                          value={value.kycStatusId}
                          key={value.kycStatusId}
                        >
                          {value.kycStatusName}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label" htmlFor="InputNameStatus">
                <strong>Name: Status</strong>
              </label>
              <br />
              <select
                style={{
                  border: `solid 1px ${
                    kyc_status_customer_name === ""
                      ? borderOutlineColor
                      : "#d5dbe0"
                  }`,
                }}
                className="form-select"
                name="name_status"
                id="InputNameStatus"
                disabled={edit_mode === "view" ? true : false}
                value={kyc_status_customer_name}
                onChange={(e) => {
                  set_kyc_status_customer_name(e.target.value);
                }}
              >
                <option value="" disabled hidden>
                  Select Status
                </option>
                {typeof kycStatusesData !== `undefined`
                  ? kycStatusesData.map((value: any, index: any) => {
                      return (
                        <option
                          value={value.kycStatusId}
                          key={value.kycStatusId}
                        >
                          {value.kycStatusName}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
          </div>
        </PanelBody>
        <Panel>
          <PanelHeader noButton>Bank Accounts</PanelHeader>
          <PanelBody>
            <div className="panel-button-row" style={{ marginBottom:-45}}>
              <div />
              <div>
                {edit_mode !== "view" ? (
                  <button
                    className="panel-button"
                    onClick={() => {
                      if (
                        customer_name === "" ||
                        customer_registry === "" ||
                        customer_email === "" ||
                        customer_id_type === "" ||
                        customer_id_number === "" ||
                        kyc_status_customer_id === "" ||
                        kyc_status_customer_name === ""
                      ) {
                        toggleSweetAlert(
                          "Please complete all mandatory fields",
                          false,
                          "info"
                        );
                        setborderOutlineColor("red");
                      } else {
                        if (customer_id === 0) saveButtonPostRequest(false);
                        setBankEditMode(false);
                        setBankViewMode(false);
                        addBankHandler();
                      }
                    }}
                  >
                    <strong>Add Bank Account</strong>
                  </button>
                ) : null}
              </div>
            </div>
          </PanelBody>
        </Panel>
        <PanelBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td>
                    <strong>Bank Account #</strong>
                  </td>
                  <td>
                    <strong>Account # Status</strong>
                  </td>
                  <td>
                    <strong>Owner Status</strong>
                  </td>
                  <td>
                    <strong>Options</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {typeof customerBankData !== `undefined`
                  ? customerBankData.map((value: any, index: any) => {
                      return (
                        <tr
                          className={
                            index % 2 !== 0 ? "table-data" : "table-data-alter"
                          }
                        >
                          <td>
                            {`${
                              value.bank_account_cci
                                ? value.bank_account_cci
                                : value.bank_account_number
                            }`}
                          </td>
                          <td>{value.kyc_status_name3}</td>
                          <td>{value.kyc_status_name4}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <button
                                className="form-button-search"
                                onClick={() => {
                                  setBankEditModeBank(value);
                                  setBankEditMode(true);
                                  setBankViewMode(true);
                                  editBankAccountButtonHandler();
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="-3 -2 30 30"
                                  fill="white"
                                  width="18px"
                                  height="18px"
                                >
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                </svg>
                              </button>
                              {edit_mode !== "view" ? (
                                <>
                                  <button
                                    className="form-button-edit"
                                    onClick={() => {
                                      setBankEditModeBank(value);
                                      setBankEditMode(true);
                                      setBankViewMode(false);
                                      editBankAccountButtonHandler();
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="-1 3 24 24"
                                      fill="white"
                                      width="17px"
                                      height="14px"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                                    </svg>
                                  </button>
                                  <button
                                    className="form-button-delete"
                                    onClick={() => {
                                      setBankToHandle(value.customer_bank_id);

                                      deleteBankAccountButtonHandler();
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0.5 2 24 24"
                                      fill="white"
                                      width="16px"
                                      height="16px"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
                                    </svg>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </PanelBody>
        <Panel>
          <PanelHeader noButton>Wallets</PanelHeader>
          <PanelBody>
            <div className="panel-button-row" style={{ marginBottom:-45}}>
              <div />
              <div>
                {edit_mode !== "view" ? (
                  <button
                    className="panel-button"
                    onClick={() => {
                      if (
                        customer_name === "" ||
                        customer_registry === "" ||
                        customer_email === "" ||
                        customer_id_type === "" ||
                        customer_id_number === "" ||
                        kyc_status_customer_id === "" ||
                        kyc_status_customer_name === ""
                      ) {
                        toggleSweetAlert(
                          "Please complete all mandatory fields",
                          false,
                          "info"
                        );
                        setborderOutlineColor("red");
                      } else {
                        if (customer_id === 0) saveButtonPostRequest(false);
                        setPhoneEditMode(false);
                        setPhoneViewMode(false);
                        addPhoneHandler();
                      }
                    }}
                  >
                    <strong>Add Phone Number</strong>
                  </button>
                ) : null}
              </div>
            </div>
          </PanelBody>
        </Panel>
        <PanelBody>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td>
                    <strong>Phone Number</strong>
                  </td>
                  <td>
                    <strong>Owner Status</strong>
                  </td>
                  <td>
                    <strong>Options</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {typeof customerPhoneData !== `undefined`
                  ? customerPhoneData.map((value: any, index: any) => {
                      return (
                        <tr
                          className={
                            index % 2 !== 0 ? "table-data" : "table-data-alter"
                          }
                        >
                          <td>{value.phoneNumber}</td>
                          <td>{value.statusName}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <button
                                className="form-button-search"
                                onClick={() => {
                                  setPhoneEditModePhone(value);
                                  setPhoneEditMode(true);
                                  setPhoneViewMode(true);
                                  editPhoneNumberButtonHandler();
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="-3 -2 30 30"
                                  fill="white"
                                  width="18px"
                                  height="18px"
                                >
                                  <path d="M0 0h24v24H0z" fill="none" />
                                  <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                                </svg>
                              </button>
                              {edit_mode !== "view" ? (
                                <>
                                  <button
                                    className="form-button-edit"
                                    onClick={() => {
                                      setPhoneEditModePhone(value);
                                      setPhoneEditMode(true);
                                      setPhoneViewMode(false);
                                      editPhoneNumberButtonHandler();
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="-1 3 24 24"
                                      fill="white"
                                      width="17px"
                                      height="14px"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                                    </svg>
                                  </button>
                                  <button
                                    className="form-button-delete"
                                    onClick={() => {
                                      setPhoneToHandle(value.customerPhoneId);

                                      deletePhoneNumberButtonHandler();
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0.5 2 24 24"
                                      fill="white"
                                      width="16px"
                                      height="16px"
                                    >
                                      <path d="M0 0h24v24H0V0z" fill="none" />
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
                                    </svg>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </PanelBody>
        <PanelHeader noButton>Notes</PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="col-md-12">
              <textarea
                disabled={edit_mode === "view" ? true : false}
                className="form-control"
                id="InputNotes"
                value={kyc_status_notes}
                onChange={(e) => {
                  set_kyc_status_notes(e.target.value);
                }}
              />
            </div>
          </div>
        </PanelBody>
        <PanelBody>
          <div
            className="panel-button-row"
            style={{ padding: "0px 5px 0px 0px" }}
          >
            {customer_id !== 0 ? (
              <div className="row">
                {created_by_user !== null && edit_mode !== undefined ? (
                  <label>{`Created by ${created_by_user} at ${moment
                    .unix(created_at)
                    .format("DD/MM/YY hh:mm A")}`}</label>
                ) : null}
                <br />
                {updated_by_user !== null && edit_mode !== undefined ? (
                  <label>{`Updated by ${updated_by_user} at ${moment
                    .unix(updated_at)
                    .format("DD/MM/YY hh:mm A")}`}</label>
                ) : null}
              </div>
            ) : (
              <div />
            )}
            <div>
              {edit_mode !== undefined ? (
                <Link to={`${path}`} style={{ paddingRight: "5px" }}>
                  <button
                    className="panel-iconbutton-close"
                    // onClick={() => {
                    //   toggleCustomerForm(false);
                    // }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="18px"
                      height="18px"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
                    </svg>
                    <strong>Close</strong>
                  </button>
                </Link>
              ) : null}
              {edit_mode !== "view" ? (
                <button
                  className="panel-iconbutton-save"
                  style={{ marginRight: "-5px" }}
                  onClick={() => {
                    saveButtonHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                  </svg>
                  <strong>Save</strong>
                </button>
              ) : null}
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default NewCustomer;
