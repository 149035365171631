import "./style.scss";
import React from "react";
import { Panel, PanelHeader, PanelBody } from "../panel/panel";
import HttpCliente from "../../services/HttpCliente";
import axios from "axios";

const AddEditPhoneNumberPopup = ({
  setPhoneEditPopup,
  phoneEditMode,
  phoneViewMode,
  phoneEditModePhone,
  toggleSweetAlert,
  customerId,
  customerData,
}) => {
  const [customer_phone_id, set_customer_phone_id] = React.useState("");
  const [customer_id, set_customer_id] = React.useState("");
  const [phone_number, set_phone_number] = React.useState("");
  const [kyc_status_phone, set_kyc_status_phone] = React.useState("");
  const [statusKycData, setStatusKycData] = React.useState([]);

  React.useEffect(() => {
    set_customer_phone_id(phoneEditModePhone.customerPhoneId);
    set_customer_id(phoneEditModePhone.customerId);
    if (phoneEditMode === true) {
      set_phone_number(phoneEditModePhone.phoneNumber);
      set_kyc_status_phone(phoneEditModePhone.status);
    }
    HttpCliente.get(`/status/kyc`)
      .then((response) => {
        setStatusKycData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });

    // HttpCliente.get(`/customer`)
    //   .then((response) => {
    //     setCustomerData(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  }, []);

  const onSaveHandler = () => {
    let sigData;
    if (phoneEditMode === true) {
      sigData = {
        kyc_status_phone: parseInt(kyc_status_phone, 10),
        phone_number: phone_number
      };
    } else {
      sigData = {
        kyc_status_phone: parseInt(kyc_status_phone, 10),
        phone_number: phone_number
      };
    }

    if (phone_number === "" || kyc_status_phone === "") {
      toggleSweetAlert("Please complete all fields", false, "info");
    } else {
      if (phoneEditMode === false) {
        axios
          .post(`/customer/${customerId}/phone-numbers`, sigData)
          .then((response) => {
            //console.log(response);
            toggleSweetAlert("Phone number Added", true, "success");
            setPhoneEditPopup(false);
          })
          .catch((error) => {
            toggleSweetAlert(`Error: ${error}`, false, "danger");
          });
      } else {
        axios
          .patch(`/customer/${customer_id}/phone-numbers/${customer_phone_id}`, sigData)
          .then((response) => {
            //console.log(response);
            toggleSweetAlert("Phone number Updated", true, "success");
            setPhoneEditPopup(false);
          })
          .catch((error) => {
            toggleSweetAlert(`Error: ${error}`, false, "danger");
          });
      }
    }
    // }
  };

  return (
    <div>
      <div id="animate" className="wrapper">
        <Panel>
          <PanelHeader noButton>{`${
            phoneEditMode ? "Edit" : "Add"
          } Phone Number`}</PanelHeader>
          <PanelBody>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label" htmlFor="phoneNumberInput">
                  <strong>Phone Number</strong>
                </label>
                <input
                  disabled={phoneViewMode}
                  type="text"
                  className="form-control"
                  id="phoneNumberInput"
                  placeholder="Enter Phone Number"
                  value={phone_number}
                  onChange={(e) => {
                    if (e.target.value.length < 12)
                      set_phone_number(e.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label className="form-label" htmlFor="inputPhoneNumberStatus">
                  <strong>Phone #: Status</strong>
                </label>
                <select
                  disabled={phoneViewMode}
                  type="text"
                  className="form-select"
                  id="inputPhoneNumberStatus"
                  placeholder="Unknown"
                  value={kyc_status_phone}
                  onChange={(e) => {
                    set_kyc_status_phone(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select Status
                  </option>
                  {typeof statusKycData !== `undefined`
                    ? statusKycData.map((value, index) => (
                        <option
                          value={value.kycStatusId}
                          key={value.kycStatusId}
                        >
                          {value.kycStatusName}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            </div>
            <div className="panel-button-row">
              <div>
                <button
                  className="panel-iconbutton-clear"
                  onClick={() => {
                    setPhoneEditPopup(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                  <strong>Cancel</strong>
                </button>
              </div>
              {phoneViewMode === false ? (
                <div>
                  <button
                    className="panel-iconbutton-save"
                    onClick={() => {
                      onSaveHandler();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="18px"
                      height="18px"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                    </svg>
                    <strong>Save</strong>
                  </button>
                </div>
              ) : null}
            </div>
            {/* )} */}
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
};

export default AddEditPhoneNumberPopup;
