import React from "react";
import TabbedPanel from "../../../components/tabbed-panel/tabbed-panel";
import { useState, useRef, useEffect, ChangeEvent } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import HttpCliente from "../../../services/HttpCliente";
import axios from "axios";
import ModalGeneral, { ModalGeneralType } from "../../../components/modal/modalGeneral";
import { useNavigate } from "react-router-dom";

interface State {
  query_type: number;
  priority_customer: boolean;
  priority_reniec: boolean;
  input_data: string;
}

const initState = {
  query_type: 1,
  priority_customer: true,
  priority_reniec: true,
  input_data: "",
};

//alert info
interface SweetAlertParam {
  title?: string;
  sub_title?: string;
  type?: ModalGeneralType;
  sweetAlertShow?: boolean;
}
const initSweetAlertParam = {
  title: "",
  sub_title: "",
  type: undefined,
  sweetAlertShow: false,
};
//end alert info


const ManualBatchProcess = () => {
  const [state, setState] = useState<State>(initState);
  const [SweetAlertParam, setSweetAlertParam] = useState<SweetAlertParam>(initSweetAlertParam);
  const [outputDataMQ, setOutputDataMQ] = useState([]);
  const [outputDataInval, setOutputDataInval] = useState([]);  
  const [outputDataVal, setOutputDataVal] = useState([]);
  const [showProcessing, setShowProcessing] = useState(false);  
  const [showInputError, setShowInputError] = useState(false);
  const [statusKycData, setStatusKycData] = React.useState<any>();
  const [activeTab, setActiveTab] = React.useState(0);

  const [kyc_status_bank_account, set_kyc_status_bank_account] = React.useState();
  const [kyc_status_bank_name, set_kyc_status_bank_name] = React.useState();  
  const [kycIdDelete, setKycIdDelete] = React.useState();
  const [predifinedNote, setPredifinedNote] = React.useState<any>();
  const [statusNoteData, setStatusNoteData] = React.useState<any>();
  const [kyc_status_user_notes, set_kyc_status_user_notes] = React.useState();
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] = React.useState(
    false
  );
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState<ModalGeneralType>("info"); 
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);

  const txtInputData = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (txtInputData.current) txtInputData.current.focus();
  }, [txtInputData]);

  const handleInputChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLTextAreaElement>) => setState({ ...state, [name]: value });

  const handleReset = () => {
    const m1 = "Are you sure you want to reset";
    const m2 = "the input text?";
    const type = "info";
    setAlertType("reset");
    toggleSweetAlertVariant3(m1, m2, type);
    return false;
  }

  const handleDeleteListtoUpdate = (kycID:any) => {
    const m1 = "Are you sure?";
    const m2 = "Do you want to remove this item from the list?";
    const type = "info";
    setKycIdDelete(kycID);
    setAlertType("deleteListtoUpdate");
    toggleSweetAlertVariant3(m1, m2, type);
    return false;
  }

  const handleResetButton = () => {
    handleResetButton2();
    txtInputData.current!.value = '';
    lineNumbers!.innerHTML=Array(1)
    .fill('<span></span>')
    .join('');

    return false;
  }

  const handleResetButton2 = () => {
    setOutputDataMQ([]);   
    setOutputDataVal([]);
    setOutputDataInval([]);  
    state.input_data='';
    setActiveTab(0);
    setShowInputError(false);
    return false;
  }

  const handleBack = () => {
    setActiveTab(0);
    //setOutputDataMQ([]);   
    //setOutputDataVal([]);
    //setOutputDataInval([]);
    return false;
  }

  const handleContinueSubmit = () => {   
    if(outputDataVal.length!=0){
      setOutputDataMQ(outputDataVal);
      setActiveTab(1);
    } 
    return false;
  }

  const handleValidateData = () => {
    const inputData = state.input_data;

    //Validations
    if (inputData.trim() === ""){
      showSweetAlertParam(true, "Input Data is requerid.", "", "info");
      return false;
    }

    const lstRow = inputData.trim().split("\n");
    const arrayDataByKYC: any = [];
    let sentData = true;
    let nroRow = 1;
    let lstRowCount = lstRow.length;

    lstRow.some(element => {    

      let continueLoop = true;
      const re = /^[0-9\b]+$/;
      if (element === '' || !re.test(element)) {
        continueLoop = false;
      }
      if (!continueLoop) {
        showSweetAlertParam(true, "Some row doesn't have the correct format.",  `Row ${nroRow}: ${(element == "" ? "it is empty" : element)}`, "info");
        sentData = false;
        return true;
      }
      arrayDataByKYC.push({kyc_id:element.trim()});
      if(nroRow>200){
        showSweetAlertParam(true, "Please enter a maximum of 200 rows.",  `Total row : ${lstRowCount}`, "info");
        sentData = false;
        return true;
      }
      nroRow += 1;

    });

    if (sentData) queryByKYC(arrayDataByKYC);
    return false;
  };

  const queryByKYC = (arrayDataByKYC: any) => {

    setOutputDataMQ([]);
    setShowProcessing(true);

    HttpCliente.get(createRequestUrl("/request/?",arrayDataByKYC))
          .then((response) => {
            setShowProcessing(false);
            let data = checkKYCQueryByKYC(response.data.data);  
            let msg="";

            if(data.length!=0){
              setOutputDataVal(data);
            } 

            let dataVal = arrayDataByKYC.map((el1: any) => ({
              kyc_id: el1.kyc_id,
              match: data.some((el2: any) => el2.kyc_id == el1.kyc_id),
            }));

            let dataInVal =  dataVal.filter((li: { match: boolean; }) => li.match == false);
            if (arrayDataByKYC.length!=0){
              if(dataInVal.length==0){
                setOutputDataMQ(data);
                setActiveTab(1);
                setShowInputError(false);
              }else{
                msg=`Ignore these errors and continue?`;   
                const m1 = "Some KYC records don't meet the requirements ";
                const m2 = msg;
                const type = "warning";  
                if(arrayDataByKYC.length==dataInVal.length){
                  showSweetAlertParam(true, m1,  "", type);
                }else{                           
                  setAlertType("continueSubmit");
                  toggleSweetAlertVariant3(m1, m2, type);       
                }
                setShowInputError(true);
                setOutputDataInval(dataVal); 
              }
            }
           

           
          })
          .catch((error) => {
            setShowProcessing(false);           
            showSweetAlertParam(true, "Failed", `Failed: ${
              error.response.data.errors ? error.response.data.errors.data : ""
            }`, "warning");
          });
  }

  
  const handleUpdateValidateData = () => {
    const inputData = outputDataMQ;
    const arrayDataByKYC: any = [];    
    const arrayDataByKYCValid: any = [];    
    let sentData = true;
    let nroRow = 1;

    //Validations
    if (inputData.length==0){
      showSweetAlertParam(true, "Input Data is requerid.", "", "info");
      return;
    }

    outputDataMQ.map((value: any, index: any) => {
      let continueLoop = true;
      const re = /^[0-9\b]+$/;
      if (value.kyc_id === '' || !re.test(value.kyc_id)) {
        continueLoop = false;
      }
      if (!continueLoop) {
        showSweetAlertParam(true, "Some row doesn't have the correct format.",  `Row ${nroRow}: ${(value.kyc_id == "" ? "it is empty" : value.kyc_id)}`, "info");
        sentData = false;
        return true;
      }
      arrayDataByKYC.push({
        kyc_id: value.kyc_id
      });
      if(value.kyc_status_customer_id==1 && value.kyc_status_customer_name==1){
        arrayDataByKYCValid.push({
          kyc_id: value.kyc_id
        });
      }
      nroRow += 1;
     });

     if (sentData) queryUpdateByKYC(arrayDataByKYC);

     return false;
  };

  const queryUpdateByKYC = (arrayDataByKYC: any) => {
     let msg_val=''
    if(kyc_status_bank_account === null || kyc_status_bank_account === undefined || kyc_status_bank_account === '') msg_val=`${msg_val} kyc_status_bank_account must not be empty.`  ;
    if(kyc_status_bank_name === null || kyc_status_bank_name === undefined || kyc_status_bank_name === '') msg_val= msg_val+'kyc_status_bank_name must not be empty. ';   

    if (msg_val!='') {
      showSweetAlertParam(true, 'info', msg_val, "info");
      return false;
    }


    let _kyc_status_bank_account = parseInt(kyc_status_bank_account!);
    let _kyc_status_bank_name = parseInt(kyc_status_bank_name!);
    let boolValid;

    const arrayStatus: any = [{
      kyc_status_bank_account:  _kyc_status_bank_account,
      kyc_status_bank_name: _kyc_status_bank_name,      
      kyc_status_user_notes: kyc_status_user_notes,
      status_id: 4
    }];
  

    const sigData = {
      data: arrayDataByKYC,
      status: arrayStatus
    };
   
    
    setOutputDataMQ([]);
    setShowProcessing(true);
    axios.patch(`/request`, sigData)
          .then((response) => {          
            setShowProcessing(false);
            showSweetAlertParam(true,'Success',
              `Batch update completed successful, total KYC requests: ${response.data.qtyRegisterUpdate}`,              
              "success"
            );
            setAlertType("confirmUpdate");
          })
          .catch((error) => {
            setShowProcessing(false);           
            if (error.response) {
              let data =error.response.data.errors;
              let msg = '';

              data.map((value: any, index: any) => {                
                msg = msg +value.message+' ';
              });
              
              showSweetAlertParam(true, "Failed", `Failed: ${
                data ? msg : ""
              }`, "warning");
            }
          });
  }

  //alert begin
  const toggleSweetAlert = (message:string, success:any, type:ModalGeneralType) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };
  const toggleSweetAlertVariant3 = (message1: string, message2: string, type: ModalGeneralType) => {
    setSweetAlertSuccessVariant3(type);
    setSweetAlertActiveVariant3(!sweetAlertActiveVariant3);
    setSweetAlertMessage1Variant3(message1);
    setSweetAlertMessage2Variant3(message2);
  };

  const [
    sweetAlertActiveVariant3,
    setSweetAlertActiveVariant3,
  ] = React.useState(false);
  const [
    sweetAlertSuccessVariant3,
    setSweetAlertSuccessVariant3,
  ] = React.useState<ModalGeneralType>("info");
  const [
    sweetAlertMessage1Variant3,
    setSweetAlertMessage1Variant3,
  ] = React.useState("Temp");
  const [
    sweetAlertMessage2Variant3,
    setSweetAlertMessage2Variant3,
  ] = React.useState("Temp");

  const [alertType, setAlertType] = React.useState("");

  //alert end


  const showSweetAlertParam = (
    sweetAlertShow: boolean,
    title?: string,
    sub_title?: string,
    type?: ModalGeneralType
  ) => {
    const dataInfo: SweetAlertParam = {
      title: title,
      sub_title: sub_title,
      sweetAlertShow: sweetAlertShow,
      type: type
    };
    setSweetAlertParam(dataInfo);
  };

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
  HttpCliente.get(`/status/kyc`)
  .then((response) => {
    setStatusKycData(response.data);
  })
  .catch((error) => {
    console.log(error.response);
  });
  
  HttpCliente.get( `/note/?per_page=250&page=1`)
  .then((response) => {
    setStatusNoteData(response.data.data);                             
  })
  .catch((error) => {
    console.log(error.response);
  });

}, []);

const createRequestUrl = (startAddress: any, arrayDataId: any) => {
  let getRequestUrl;  let defaultValues;
  let per_page=200;  let page=0;  let sort='status_name';
  let direction='asc';  let list_id='';  let list_id_temp: any = [];

 arrayDataId.map((value: any, index: any) => (                   
     list_id_temp.push(value.kyc_id)
 ));
 list_id=list_id_temp.join(',');

  defaultValues = `per_page=${per_page}&page=${    page + 1
  }&sort=${sort}&direction=${direction}`;
  getRequestUrl = `${startAddress}${defaultValues}&list_id=${list_id}`;
  return getRequestUrl;
};

const checkKYCQueryByKYC = (data: any) => {
  let dataProcess: any = [];
  data.map((value: any, index: any) => {
    let bank_account_number;
    let customer_id_type;
    let kyc_status_customer_name;
    bank_account_number= (value.bank_account_cci)?value.bank_account_cci:value.bank_account_number;    
    customer_id_type=value.customer_id_type.trim();
    kyc_status_customer_name= value.kyc_status_customer_name;
    if(value.status_id==3 
      && (value.kyc_status_customer_id==1)
      && (kyc_status_customer_name==1 || kyc_status_customer_name==2)){
        if(customer_id_type=="DNI"){
          dataProcess.push({
            kyc_id:value.kyc_id,
            customer_fullname:value.customer_fullname,
            bank_account_number:bank_account_number,
            customer_id_number:value.customer_id_number,
            customer_id_type:value.customer_id_type,
            kyc_status_customer_id: value.kyc_status_customer_id,
            kyc_status_customer_name: value.kyc_status_customer_name
          });
        }
    }
  });
  return dataProcess;
};

const handleDeleteRow=(kycId: any) =>{
  setOutputDataMQ(l => l.filter((li: {kyc_id: any}) => li.kyc_id !== kycId))
  return false;
}



let temp_statusKycValidInvalid;
temp_statusKycValidInvalid=(typeof statusKycData !== `undefined`)
? statusKycData.map((value: any, index: any) => (   
  (value.kycStatusId==1 || value.kycStatusId==2 || value.kycStatusId==9)?
    <option
    value={value.kycStatusId}
    key={value.kycStatusId}
  >
    {value.kycStatusName}
  </option>
  :null   
  ))
: null;

const textarea: any = document.querySelector('.line-numbers textarea')
const lineNumbers: any = document.querySelector('.line-numbers');
const handleKeyUp = (e: any) => {
  const numberOfLines = e.target.value.split('\n').length
  lineNumbers!.innerHTML = Array(numberOfLines)
   .fill('<span></span>')
   .join('')
}

const handleKeyDown = (e: any) => {
  if (e.key === 'Tab') {
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    textarea.value = textarea.value.substring(0, start) + '\t' + textarea.value.substring(end);
    e.preventDefault()
  }
}

let  path = window.location.pathname;
path = path.slice(0, path.lastIndexOf("/"));
path = path.slice(0, path.lastIndexOf("/"));
const navigate = useNavigate();
const handleSubmitEditButton =()=>{
  navigate(`${path}`);
}
const onAddNoteHandler = () =>{
  let msg_val='';
  if(predifinedNote === null || predifinedNote === undefined || predifinedNote === '') msg_val=`${msg_val} Predefined Notes must not be empty.`  ;
  if (msg_val!='') {
    toggleSweetAlert(
      msg_val,
      true,
      "info"
    );
    return false;
  }
  let dataInVal =  statusNoteData.filter((li: {note_id: any}) => li.note_id == predifinedNote);
  if(typeof dataInVal  !== `undefined`){
    let _kyc_status_user_notes:any='';
    if(kyc_status_user_notes== null){       
      _kyc_status_user_notes=dataInVal[0].description;
    }
    else{
      _kyc_status_user_notes=kyc_status_user_notes+'-'+dataInVal[0].description;
    }    
    set_kyc_status_user_notes(_kyc_status_user_notes);
  }     
}

  return (
    <div id="animate">
      <h1 className="page-header">Batch Process</h1>
      <Panel>
        <PanelBody>
          <div id="animate">
            <TabbedPanel
              tabs={["KYC Input", "KYC List"]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            >
              {activeTab === 0 ? (
                <div>
                  <div>
                  <PanelHeader noButton>Input Data</PanelHeader>
                  <PanelBody>
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <label className="form-label"><strong>Requirements:</strong> The KYC records should be editable ("Process" status) and only DNIs  (not CE or Pass)</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className="form-label">Input KYC ID:</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`form-group col-md-12 div-scroll ${showInputError ? "div-scroll-25" : "div-scroll-50"}`}>
                        <div className="editor">
                          <div className="line-numbers"><span></span></div>
                          <textarea
                            name="input_data"
                            rows={8}
                            ref={txtInputData}
                            onChange={handleInputChange}
                            onKeyUp={handleKeyUp}
                            onKeyDown={handleKeyDown}                          
                          />       
                        </div>
                      </div>
                    </div>
                  { showInputError    
                       ? 
                          (
                            <>
                            <div className="row">
                              <div className="col-md-12 mb-2">
                                <span>Input error log:</span>  
                              </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 div-scroll div-scroll-25">
                                <div className="editor ">
                                  <div className="line-numbers">
                                  {typeof outputDataInval !== `undefined`
                                      ? outputDataInval.map((value: any, index: any) => {
                                        return (
                                          <>
                                          {!value.match        
                                          ?  <span></span>
                                        :null    
                                        }
                                        </>
                                        );
                                      })
                                      : null}
                                    
                                  </div>
                                  <p>
                                  {typeof outputDataInval !== `undefined`
                                      ? outputDataInval.map((value: any, index: any) => {
                                        return (
                                          <>
                                          {!value.match        
                                            ?  <div className="form-row">{value.kyc_id}</div>
                                          :null    
                                          }
                                          </>       
                                        );
                                      })
                                      : null}
                                  </p>   
                                </div>
                              </div>
                            </div>                  
                            </>
                          )

                  :null    
                  }                  
                  </PanelBody>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                  <PanelHeader noButton>KYC List to Update</PanelHeader>
                    <PanelBody className="div-scroll">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                             <td style={{width:"5%"}}>
                                <strong>#</strong>
                              </td>
                              <td style={{width:"5%"}}>
                                <strong>KYC ID</strong>
                              </td>
                              <td style={{width:"15%"}}>
                                <strong>ID NUMBER</strong>
                              </td>   
                              <td style={{width:"35%"}}>
                                <strong>Customer Name</strong>
                              </td>
                              <td style={{width:"25%"}}>
                                <strong>Bank Account Number</strong>
                              </td>      
                              <td style={{width:"10%"}}>
                              <strong>Options</strong>
                              </td>                                                
                            </tr>
                          </thead>
                          <tbody>
                          {typeof outputDataMQ !== `undefined`
                            ? outputDataMQ.map((value: any, index: any) => {
                              return (
                                <tr className={index % 2 !== 0 ? "table-data" : "table-data-alter"}>         
                                  <td style={{ textAlign:"right"}}>{index+1}</td>                                           
                                  <td style={{ textAlign:"right"}}>{value.kyc_id}</td>
                                  <td>{value.customer_id_type}  {value.customer_id_number}</td>  
                                  <td style={ (value.kyc_status_customer_name == 2) ? { color:"#FF0000"} : undefined}>{value.customer_fullname}</td>
                                  <td style={{ textAlign:"right"}}>{value.bank_account_number}</td>    
                                  <td >
                                  <button className="form-button-delete" onClick={() => {
                                    handleDeleteListtoUpdate(value.kyc_id);
                                    }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.5 2 24 24" fill="white" width="16px" height="16px">
                                      <path d="M0 0h24v24H0V0z" fill="none"></path>
                                      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"></path>
                                    </svg>
                                  </button>
                                    </td>                                                                      
                                </tr>
                              );
                            })
                            : null}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                  </div>
                  <div>
                  <PanelHeader noButton>Customer Bank Account</PanelHeader>
                    <PanelBody>  
                      <div className="row">
                        <div className="col-md-3">
                          <label className="form-label"  htmlFor="InputAccountNumberStatus">
                            <strong>Bank Account #: Status</strong>
                          </label>
                          <br />
                          <select                         
                            className="form-select"
                            name="account-number-status"
                            id="InputAccountNumberStatus"
                            value={kyc_status_bank_account}
                            onChange={(e: any) => {
                              set_kyc_status_bank_account(e.target.value);
                            }}
                          >   
                             <option value='' key=''/>                           
                              {temp_statusKycValidInvalid}
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <label className="form-label" htmlFor="InputAccountNameStatus">
                            <strong>Name: Status</strong>
                          </label>
                          <br />
                          <select                           
                            className="form-select"
                            name="account-name-status"
                            id="InputAccountNameStatus"
                            value={kyc_status_bank_name}
                            onChange={(e: any) => {
                              set_kyc_status_bank_name(e.target.value);
                            }}
                          >
                            <option value='' key=''/>                                
                            {temp_statusKycValidInvalid}
                          </select>
                        </div>
                        <div className="col-md-4">
                          <label  className="form-label" htmlFor="InputNote">
                            <strong>Predefined Notes</strong>
                          </label>
                          <br />
                          <select                           
                            className="form-select"
                            name="note"
                            id="InputNote"
                            value={predifinedNote}                           
                            onChange={(e:any) => {
                              setPredifinedNote(e.target.value);
                            }}
                          >
                            <option value='' key=''/>     
                            {typeof statusNoteData !== `undefined`
                              ? statusNoteData.map((value: any, index: any) => (
                                  <option
                                    value={value.note_id}
                                    key={value.note_id}
                                  >
                                    {value.description}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                        <div className="col-md-2">
                          <br/>
                        <button                         
                          className="panel-button"
                          onClick={() => {
                            onAddNoteHandler();
                          }}                        >
                          
                          <strong>Add</strong>
                        </button>                   
                        </div>
                      </div>
                    </PanelBody>
                  </div>
                  <div>
                    <PanelHeader noButton>Notes</PanelHeader>
                    <PanelBody>
                      <div className="row">
                        <div className="col-md-12">
                        <label  className="form-label" htmlFor="InputNote">
                            <strong>User Notes</strong>
                          </label>
                          <br />
                          <textarea                           
                            className="form-control"
                            id="InputNotes"
                            value={kyc_status_user_notes}
                            onChange={(e:any) => {
                              set_kyc_status_user_notes(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </PanelBody>
                  </div>
                </div>
              )}
            </TabbedPanel>
          </div>
        </PanelBody>
      </Panel>
     
      {activeTab === 0 ? (
        <div className="panel-button-row">
          <div >
          <button
              className="panel-iconbutton-clear"
              onClick={() => {
                handleReset();
                }}
            >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              width="20px"
              height="20px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z" />
            </svg>
            <strong>Clear</strong>
          </button>
          </div>
          <div>
          <button
              className="panel-iconbutton-search"
              onClick={() => {
                handleValidateData();
              }}
            >   
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>           
              <strong>Submit</strong>
          </button>
          </div>
        </div>

         ) : (
          <div className="panel-button-row">
            <div >
            <button
              className="panel-button-reset"
              onClick={() => {
                handleBack();
                }}
            >
              <strong>Back</strong>
          </button>
            </div>
            <div>
            <button
                  className="panel-iconbutton-search"
                  style={{ marginRight: "-5px"}}
                  onClick={() => {
                    handleUpdateValidateData();
                  }}
                > 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z"></path>
                  </svg>             
                  <strong>Update</strong>
                </button>
            </div>
          </div>
          )}

{sweetAlertActive ? (
        <ModalGeneral
          isModalOpen={sweetAlertActive}
          onlyModal={false}
         // confirmBtnBsStyle={`${sweetAlertSuccessBool ? "success" : "error"}`}
          type={`${sweetAlertSuccess}`}
          onConfirm={() => {
            setSweetAlertActive(false);
            //itd-256 begin
            if (alertType === "submitEdit") {
              handleSubmitEditButton();
              setAlertType("");
            }
            //itd-256 end           
          }}
          onClose={() => {
            setSweetAlertActive(false);
          }}
        >
          <div>
            <h3>{sweetAlertMessage}</h3>
          </div>
        </ModalGeneral>
      ) : null}

{sweetAlertActiveVariant3 ? (
        <ModalGeneral
          isModalOpen={sweetAlertActiveVariant3}
          onlyModal={false}
          type={sweetAlertSuccessVariant3}
          title={sweetAlertMessage1Variant3}
          onConfirm={() => {
            if (alertType === "reset") {
              handleResetButton();
              setAlertType("");
            } else if (alertType === "deleteListtoUpdate") {
              handleDeleteRow(kycIdDelete);
              setAlertType("");
            } else if (alertType === "continueSubmit") {
              handleContinueSubmit();
              setAlertType("");
            }
            setSweetAlertActiveVariant3(false);
          }}
          onCancel={() => {
            setAlertType("");
            setSweetAlertActiveVariant3(false);
          }}
          cancelBtnText="No"
          confirmBtnText="Yes"
        >
          <h4>{sweetAlertMessage2Variant3}</h4>
        </ModalGeneral>
      ) : null}
      
      {SweetAlertParam.sweetAlertShow && (
        <ModalGeneral
          isModalOpen={SweetAlertParam.sweetAlertShow}
          onlyModal={false}
          confirmBtnText="Ok"
          type={SweetAlertParam.type!}
          title={SweetAlertParam.title}
          desc={SweetAlertParam.sub_title}
          onConfirm={() => {
            if (alertType === "confirmUpdate") {
              handleResetButton2();
              setAlertType("");
            }
            showSweetAlertParam(false);
          }}
          onClose={() => {
            showSweetAlertParam(false);
          }}
        ></ModalGeneral>
      )}
      {showProcessing && (
        <div className="backgroundProcessing">
          <div className="bpWaiting">Processing...</div>
        </div>
      )}
    </div>
  );
};

export default ManualBatchProcess;
